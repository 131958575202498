import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import {
  userPageContainer,
  pad,
  compareButton,
  header,
} from './UserPage.module.scss';
import Offer from '../Offer/Offer.tsx';
import useAuth from '../../hooks/useAuth';
import getOfferAPI from '../Flow/apiFunctions/getOfferAPI';

const UserPage: FunctionComponent = () => {
  const { logout, isAuthenticated } = useAuth();
  const [count, setCount] = useState(0);
  const [bank_id, setBankId] = useState(0);
  const [tasaDescuentoCredit, setTasaCredit] = useState(0);
  const [tasaDescuentoDebit, setTasaDebit] = useState(0);
  const [total_year_cost, setTotalCost] = useState(0);
  const [sector, setSector] = useState(0);
  const [date, setDate] = useState(0);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  useEffect(() => {
    (async () => {
      const res = await getOfferAPI();
      if (res) {
        // console.log(res);
        setBankId(res.data.bank_id);
        setTotalCost(res.data.costo_anual);
        setSector(res.data.sector_id);
        setTasaCredit(res.data.tasa_credit);
        setTasaDebit(res.data.tasa_debit);
        setDate(res.data.date_offer);
      } else {
        alert(
          'Error obtiendo el data del usuario, contactanos en info@puntoterminal.mx o 56 4238 4307 y le ayudamos.'
        );
      }
    })();
  }, []);

  return (
    <>
      <Container className="d-flex justify" id="wrapper">
        <div className="border-end bg-white" id="sidebar-wrapper">
          <div className="list-group list-group-flush">
            <Link to="/usario">
              <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                Home
              </Button>
            </Link>

            {/* <Link to="/usario-contacto">
              <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                Datos de Contacto
              </Button>
            </Link>

            <Link to="/cambia-contrasena">
              <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                Cambiar contraseña
              </Button>
            </Link> */}

            <Link to="/">
              <Button
                className="list-group-item list-group-item-action list-group-item-light p-3"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Link>
          </div>
        </div>
        <div id="page-content-wrapper">
          <div className={userPageContainer}>
            <div className={pad} />

            <div className={header}>
              <h1>Su oferta eligida</h1>
            </div>
            <Offer
              language="Español"
              handleChange="notyet" // {props.handleChange}
              // Offerdata
              bank_id={bank_id}
              tasaDescuentoCredit={tasaDescuentoCredit}
              tasaDescuentoDebit={tasaDescuentoDebit}
              total_year_cost={total_year_cost}
              sector={sector}
              userPage
              contractDate={date}
              // Form data
              chosenProvider="notNeededForThisPage"
            />
            <div className={pad} />
            <p>
              PuntoTerminal te recomienda hacer la comparación una vez cada año
              ya que nosotros actualizamos las ofertas consecutivamente.
            </p>
            <Link to="/preferencias">
              <Button className={compareButton} variant="success" size="lg">
                <div>¡Compara y encuentra una oferta aún mejor!</div>
              </Button>
            </Link>

            <div className={pad} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default UserPage;
