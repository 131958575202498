/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import UserPage from '../komponents/UserPage/UserPage';
import LoginComponent from '../komponents/LoginComponent/LoginComponent';
import useAuth from '../hooks/useAuth';

const Usario: FunctionComponent = () => {
  const { state, isAuthenticated } = useAuth();

  return (
    <>
      <Header />
      {isAuthenticated ? <UserPage /> : <LoginComponent />}
      <Footer />
    </>
  );
};

export default Usario;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="UserPage PuntoTerminal" />
    <title>Pagina del usario - PuntoTerminal.mx</title>
  </>
);
