import * as nprogress from 'nprogress';
import axios from 'axios';
import useFlowUserStore from '../../FlowStore/FlowUserStore';

import 'nprogress/nprogress.css';

const apiURL = process.env.GATSBY_API_URL;

async function getOfferAPI() {
  nprogress.start();

  // Import states
  const { email } = useFlowUserStore.getState();

  const user = {
    email,
  };

  const urlGetOfferData = `${apiURL}/getOffer`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  let success = false;
  let res;
  try {
    res = await axios.post(urlGetOfferData, JSON.stringify(user));
    // console.log(res.data);
    success = true;
  } catch (error) {
    // console.log(error);
  }

  nprogress.done();
  if (success) {
    return res.data;
  }
  return success;
}

export default getOfferAPI;
